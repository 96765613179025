import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

export const PageTop = styled.div`
  position: relative;
  display: inline-block;
  margin: 78px 0 99px 0;
  ${mq.lt.md} {
    margin: 24px 0 0 0;
  }
`;

export const ADSIconHolder = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  transform: translate(75%, -50%);
  ${mq.lt.md} {
    width: 120px;
    right: -10%;
    path {
      stroke-width: 8;
    }
  }
`;

export const Text = styled.p`
  font-size: 3.6rem;
  line-height: 4.3rem;
  color: #ffffff;
  margin: 42px 0;
  font-weight: 400;
  ${mq.lt.lg} {
    font-size: 3rem;
    line-height: 3.4rem;
  }
  ${mq.lt.md} {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 24px 0;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 84px;
  padding: 0;
  div {
    li:last-child {
      ${mq.lt.md} {
        border-bottom: 2px solid #fbfbfb;
      }
    }
    &:last-child {
      margin-top: 82px;
      ${mq.lt.md} {
        margin-top: 0;
      }
    }
    li {
      &:first-child {
        border-bottom: 2px solid #fbfbfb;
      }
    }
  }
  ${mq.lt.md} {
    flex-direction: column;
    gap: 0;
  }
`;

export const ListItem = styled.li`
  color: #ffffff;
  font-size: 3.6rem;
  line-height: 4.3rem;
  flex-basis: 50%;
  padding: 42px 0;
  display: flex;
  align-items: center;
  gap: 24px;
  font-weight: 400;
  > * {
    &:first-child {
      flex-shrink: 0;
    }
  }
  ${mq.lt.lg} {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
  ${mq.lt.md} {
    font-size: 2rem;
    line-height: 2.6rem;
  }
`;

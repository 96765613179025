import React from 'react';

export const WaveAdvertising = () => (
  <svg width="100%" height="100%" viewBox="0 0 1728 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M170 107.306C144.248 198.146 6.98786 45.2125 0 0H1728C1724.78 7.01255 1582.5 200.753 1638.5 102.969C1684.47 22.6952 1597.34 91.6245 1601 25.3361C1472.5 168.308 1485.05 56.2574 1432.67 95.7923C1367.19 145.211 1416.28 35.9858 1269.5 134.823C1152.08 213.893 1093.06 87.7771 1093.5 16.9901C1068.79 191.075 934.5 0 895.5 79.2641C815.019 242.834 834.268 37.3349 676.5 98.6616C550.286 147.723 492.5 52.7908 492.5 25.3361C475.361 51.8647 371.329 25.6336 320 57.2297C255.838 96.7249 212 -40.8515 170 107.306Z"
      fill="#FBFBFB"
    />
  </svg>
);

import React from 'react';

export const ADSIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 278 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 173.026C27.9569 118.677 39.3993 88.1041 51.7199 33.2583C65.5897 78.5254 70.7443 103.566 73.1897 147.366M12.0833 120.067L90.5306 86.2173M102.917 29.4367C114.09 72.5395 116.714 96.9421 116.129 140.814C160.17 116.428 224.634 60.0109 130.167 29.4367M275 29.4176C275 -3.34055 221.513 -6.59747 206.963 25.0688C191.517 58.6836 227.961 63.4837 250.273 73.1663C300.678 95.0409 236.007 204.411 199.531 109.694"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

import React from 'react';
import { Layout } from '../components/layout';
import { AdvertisingTemplate } from '../components/templates/advertising ';

const AdvertisingPage = () => {
  return (
    <Layout>
      <AdvertisingTemplate />
    </Layout>
  );
};

export const Head = () => <title>Bermond Management</title>;

export default AdvertisingPage;

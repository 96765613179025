import React from 'react';
import { StyledBottom, StyledContainer } from '../../../config/styled/shared';
import { WaveAdvertising } from '../../icons/WaveAdvertising';
import { Typography } from '../../../config/styled/typography';
import { ADSIcon } from '../../icons/ADSIcon';
import { Rings } from '../../icons/Rings';
import * as S from './styles';

export const AdvertisingTemplate = () => {
  return (
    <StyledBottom>
      <WaveAdvertising />
      <StyledContainer>
        <S.PageTop>
          <Typography.XXL as="h1" responsive css={{ color: '#ffffff', zIndex: 100 }}>
            Advertising
          </Typography.XXL>
          <S.ADSIconHolder>
            <ADSIcon />
          </S.ADSIconHolder>
        </S.PageTop>
        <S.Text>
          Advertising is when a business pays to place its messages or branding in a particular area. Using advertising,
          businesses can promote their products and services and build their corporate culture and brand. By using the
          right and strategic approach, advertising can increase sales and attract new customers.
          <br />
          <br />
          Advertising campaigns offer countless benefits. Advertising can help you:
        </S.Text>
        <S.List>
          <div>
            <S.ListItem>
              <Rings.One />
              <span>Describe the product or service to customers</span>
            </S.ListItem>
            <S.ListItem>
              <Rings.Three />
              <span>Demonstrate your product's superiority to your customers</span>
            </S.ListItem>
          </div>
          <div>
            <S.ListItem>
              <Rings.Two />
              <span> Make the brand or culture more appealing to customers</span>
            </S.ListItem>
            <S.ListItem>
              <Rings.Four />
              <span> Generate customer demand or desire for products or services</span>
            </S.ListItem>
          </div>
        </S.List>
      </StyledContainer>
    </StyledBottom>
  );
};

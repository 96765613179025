import React from 'react';

const One = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 17.2547C109.559 -36.3998 31.6835 82.4088 9.82962 57.285C-9.24508 35.3563 25.1947 -13.7149 62.65 23.2851"
      stroke="#50A6DB"
      strokeWidth="6"
    />
  </svg>
);

const Two = () => (
  <svg width="66" height="72" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 3.60907C72.5 12.6091 31.4091 88.4382 8.65289 63.3144C-11.2094 41.3857 24.6523 -7.68556 63.6541 29.3144"
      stroke="#50A6DB"
      strokeWidth="6"
    />
  </svg>
);

const Three = () => (
  <svg width="66" height="60" viewBox="0 0 66 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4989 23.9025C82.9946 -4.5 31.4072 76.3654 8.65251 51.2416C-11.2084 29.3128 24.6508 -19.7584 63.65 17.2416"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

const Four = () => (
  <svg width="66" height="62" viewBox="0 0 66 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.1504 20.9946C83.6475 29.9946 31.4072 76.1184 8.65251 50.9946C-11.2084 29.0658 24.6508 -20.0054 63.65 16.9946"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);

export const Rings = {
  One,
  Two,
  Three,
  Four,
};
